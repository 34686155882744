@import "../../../styles/global.scss";

.Contact {
  min-height: initial;
  background-color: $dark-3;
  padding: 3rem 0;
  width: 100vw;
  margin: 4rem 2rem 0 2rem;
  section {
    max-width: 37rem;
  }
  .section-title {
    @include flex-center;
  }
  .section-content {
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 8px;
    border-width: 2px 1px 1px 2px;
    p {
      margin: 0 0 1rem 0;
    }
    .links {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      .button {
        margin: 1rem 1rem 1rem 0;
      }
      .icons {
        margin-top: 1rem;
        @include flex-center;
        a {
          height: fit-content;
          color: $light-1;
          margin-left: 1rem;
          border-radius: 100%;
          &:nth-child(1) {
            margin-left: 0;
          }
        }
        .fa {
          background-color: $dark-2;
          padding: 1rem;
          border-radius: 100%;
          height: 0.9rem;
          width: 0.9rem;
          @include flex-center;
          border: 2px solid transparent;
          font-size: 1.5rem;
          cursor: pointer;
          transition: 0.3s;
          &:hover {
            background-color: $dark-1;
            color: $light-5;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 368px) {
  .Contact {
    background-color: transparent;
    section {
      border-color: transparent;
      padding: 0;
    }
  }
}