@import "../../styles/global.scss";

.Hero {
  padding-top: 0;
  section {
    margin-top: 8rem;
  }
  .greeting {
    margin-bottom: 0.5rem;
    margin-left: 0.1rem;
  }
  .name {
    font-size: 4rem;
    font-weight: 700;
    color: $light-5;
    margin-bottom: 0.5rem;
  }
  .role {
    font-size: 3rem;
    font-weight: 700;
  }
  .description {
    margin: 1rem 0;
    max-width: 42rem;
    p {
      margin: 0.5rem 0.1rem;
      &.open-to-work {
        color: $light-2;
      }
    }
    .open-to-work {
      img {
        width: 0.7rem;
        margin-right: 0.5rem;
      }
    }
  }
  .Button {
    margin: 0.5rem 0.2rem;
  }
  a.hero-contact {
    border: 1px solid red;
    cursor:crosshair;
  }
}

@media screen and (max-width: 1024px) {
  .Hero {
    .name {
      font-size: 3rem;
    }
    .role {
      font-size: 2.5rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .Hero {
    .name {
      font-size: 2.7rem;
    }
    .role {
      font-size: 1.8rem;
    }
  }
}

@media screen and (max-width: 638px) {
  .Hero {
    .name {
      font-size: 2rem;
    }
    .role {
      font-size: 1.6rem;
    }
  }
}