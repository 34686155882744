$red: #ff194b;
$yellow: #ffde20;
$green: #13ff6d;
$blue: #3ea8ff;
$purple: #bd6dff;
$primary: #00ffff;
$primary-transparent: #00ffff15;
$black: #040404;
$white: #f5f5f5;
$dark-1: #080914;
$dark-2: #101225;
$dark-3: #171a33;
$dark-4: #24284d;
$dark-transparent: #0809147e;
$light-1: #8a8e9e;
$light-2: #b7bbcc;
$light-3: #c0c0c0;
$light-4: #d0d0d0;
$light-5: #e0e0e0;
$light-transparent: #ffffff3a;

$font-code: "Roboto Mono", monospace;
$font-main: "Roboto", sans-serif;