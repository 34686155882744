@import "../styles/global.scss";

.App {
  @include flex-center;
  flex-direction: column;
  #about,
  #skills,
  #projects,
  #contact {
    margin-bottom: 6rem;
  }
  .nav-container {
    height: 0;
    overflow: visible;
    position: sticky;
    top: 0;
    z-index: 2;
  }
  .scroller {
    align-self: flex-end;
    height: 5rem;
    width: 5rem;
    right: 3rem;
    bottom: 3rem;
    border-radius: 100%;
    background-color: $dark-3;
    backdrop-filter: blur(5px);
    cursor: pointer;
    position: fixed;
    overflow: visible;
    opacity: 0.7;
    @include flex-center;
    transition: 0.3s;
    border: 2px solid transparent;
    zoom: 80%;
    background-image: url("../images/arrow.png");
    background-position-x: 1.7rem;
    background-position-y: 1.5rem;
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
    transform: rotate(-90deg);
    &:hover {
      opacity: 1;
    }
    &.hide {
      bottom: -10rem;
    }
  }
  footer {
    width: 100%;
    height: 7rem;
    font-size: 0.8rem;
    @include flex-center;
    span {
      font-family: $font-code;
      margin-left: 0.4rem;
    }
  }
}

@media screen and (max-width: 640px) {
  .App {
    .Nav {
      // height: 3rem;
    }
    .scroller {
      zoom: 60%;
      right: 2rem;
      bottom: 3rem;
    }
  }
}