@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    height: 0.25rem;
    width: 0.25rem;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $light-1;
    border-radius: 15px;
  }
  &::-webkit-scrollbar-corner {
    background: $dark-1;
  }
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin fill-viewport {
  width: fit-content;
  max-width: 100vw;
  margin: 2rem 3rem 0 3rem;
  // border: 1px solid red;
  min-height: 90vh;
}

@mixin theme-border {
  border-color: $light-1 $primary $primary $light-1;
  border-style: dashed solid solid dashed;
  border-width: 2px 1px 1px 2px;
  border-radius: 4px;
  padding: 1rem;
}