@import "../styles/global.scss";

.Nav {
  width: 98vw;
  @include flex-center;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  position: fixed;
  left: 0;
  top: 0;
  transition: 0.5s;
  transition-timing-function: ease-out;
  background-color: transparent;
  border-bottom: 1px solid transparent;
  z-index: 2;
  height: 3.5rem;
  a {
    height: fit-content;
    width: fit-content;
  }
  .nav-right > a {
    margin-left: 3rem;
    :not(.resume) {
      margin-top: 0.6rem;
    }
  }
  &.pop {
    backdrop-filter: blur(3px);
    background-color: $dark-transparent;
    border-bottom: 1px solid $light-1;
  }
  &.expand {
    height: 100vh;
    .nav-dropdown {

      bottom: 0;
    }
  }
  .nav-left,
  .nav-right {
    display: flex;
    margin: 0.5rem 1vw;
    padding-right: 0rem;
  }
  .nav-dropdown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -55rem;
    bottom: initial;
    opacity: 0;
    transition: 0.5s;
    transition-timing-function: ease-out;
    margin: auto;
    width: 92vw;
    .nav-item {
      margin: 0rem 0rem 4rem 0rem;
      font-size: 1.2rem;
      &.resume {
        padding: 1.2rem;
      }
    }
  }
  .fa {
    display: none;
    font-size: 2rem;
    color: $light-1;
    margin-top: 0.5rem;
    margin-right: 2rem;
    cursor: pointer;
    &:hover,
    &.active {
      color: $light-5;
    }
  }
  img {
    width: 2rem;
  }
  .nav-item {
    font-family: $font-code;
    font-size: 0.8rem;
    user-select: none;
    color: $light-2;
    cursor: pointer;
    transition: 0.3s;
    border: 1px solid transparent;
    border-radius: 4px;
    &:hover {
      color: $primary;
    }
    &.resume {
      border-color: $primary;
      color: $primary;
      padding: 0.6rem 0.7rem 0.7rem 0.7rem;
      &:hover {
        background-color: $primary-transparent;
      }
    }
    &.selected {
      color: $primary;
    }
  }
}

@media screen and (max-width: 768px) {
  .Nav {
    .nav-right {
      display: none;
    }
    &.expand {
      .nav-dropdown {
        display: flex;
        opacity: 1;
        top: 5.5rem;
      }
    }
    .fa {
      display: flex;
    }
  }
}